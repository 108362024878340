import React from 'react';
import styled, { css } from 'styled-components';
import { Carousel } from '@catalogo/theme-carousel-with-bullets';
import PickupStore from '@catalogo/ui-americanas-desktop-svg/pickup-store.svg';

const VitrineCarousel = props => {
  const { child, renderComponent, descriptionText, buttonText, buttonLink, isPickupStore } = props;

  return (
    <BoxUI>
      <HeaderUI>
        {isPickupStore === 'sim' && (
          <PickupWrapper>
            <PickupStoreUI />
            <Title>pegue na loja</Title>
            <P>hoje!</P>
          </PickupWrapper>
        )}
        {descriptionText && <Description>{descriptionText}</Description>}
      </HeaderUI>
      <Carousel gap={20} arrow={true}>
        {child.map(item => renderComponent({ ...item, orientation: 'column', viewType: 'carousel' }))}
      </Carousel>
      {buttonText && (
        <ButtonContaninerUI>
          <Button href={buttonLink}>{buttonText}</Button>
        </ButtonContaninerUI>
      )}
    </BoxUI>
  );
};

const BoxUI = styled.div`
  flex-direction: column;
  max-width: 1250px;
  height: 100%;
  width: 100%;
  display: flex;
`;

const HeaderUI = styled.div`
  flex-direction: column;
  padding-bottom: 15px;
`;

const PickupWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const PickupStoreUI = styled(PickupStore)`
  fill: ${({ theme }) => theme.primaryColor};
  width: 40px;
  height: 40px;
`;

const Title = styled.p`
  ${({ theme }) => `
    color: ${theme.primaryColor};
  `}
  font-weight: bold;
  font-size: 26px;
`;

const P = styled.p`
  font-weight: bold;
  font-size: 26px;
  padding-left: 7px;
`;

const Description = styled.p`
  font-size: 14px;
`;

const ButtonContaninerUI = styled.div`
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
`;

const Button = styled.a`
  display: flex;
  text-transform: lowercase;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  padding: 12px;
  margin: 20px;
  justify-content: center;
  width: fit-content;
  ${({ theme }) => css`
    border: 2px solid ${theme.primaryColor};
    color: ${theme.primaryColor};
  `}
`;

export default VitrineCarousel;
