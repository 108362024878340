import { useQuery } from '@apollo/client';
import vitrine from '../queries/vitrine.graphql';

export const useGetVitrine = ({
  content,
  filters = [],
  limit = 20,
  offset = 0,
  sortBy = 'relevance',
  source = 'nanook',
  skip,
}) => {
  const { loading, error, data, fetchMore, refetch, variables } = useQuery(vitrine, {
    variables: {
      content,
      filters,
      limit,
      offset,
      sortBy,
      source,
    },
    skip,
    notifyOnNetworkStatusChange: true,
  });

  return { loading, error, data, fetchMore, refetch, variables };
};
