export const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
};

export const mountFilterVitrine = ({ storeId, tag, categoryId }) => {
  const itemsObj = removeEmpty({ storeId, tag, categoryId });
  return Object.keys(itemsObj).map(item => ({
    id: getIdFilter(item),
    value: itemsObj[item],
    fixed: true,
  }));
};

export const getIdFilter = value => {
  switch (value) {
    case 'categoryId':
      return 'category.id';
    case 'storeId':
      return 'variation.sellerID';
    default:
      return value;
  }
};

const mountChildren = (productId, publication) => ({
  type: 'zion-product',
  productId,
  title: publication?.shelfTitle,
});

export const getChildren = (children, productIds, publication) => {
  if (children.length) {
    return children;
  }

  return productIds.map(id => mountChildren(id, publication));
};

export const getButtonInfo = ({ buttonLink, seeMoreLink, buttonText, viewType }) => {
  const list = {
    carousel: {
      link: buttonLink,
      text: buttonText,
    },
    grid: {
      link: seeMoreLink,
      text: 'ver mais',
    },
  };

  return list[viewType];
};
