import React from 'react';
import { getIn } from 'seamless-immutable';
import { mountFilterVitrine, getChildren, getButtonInfo } from '@catalogo/service-americanas-vitrine/src/helpers';
import { useGetVitrine } from '../hook/vitrine';

export const withVitrine = WrapperdComponent => {
  const EnhancedVitrine = ({ publication, history, renderComponent }) => {
    const {
      content,
      storeId,
      tag,
      categoryId,
      buttonLink,
      buttonText,
      viewType,
      isPickupStore,
      seeMoreLink,
      descriptionText,
      ignoreScrollBar,
      shelfTitle,
      children,
    } = publication;
    const { data, loading, error } = useGetVitrine({
      content,
      filters: mountFilterVitrine({ storeId, tag, categoryId }),
      limit: 20,
      offset: 0,
      sortBy: 'relevance',
      skip: !!children.length,
      source: categoryId ? 'blanca' : 'nanook',
    });

    const productIds = getIn(data, ['search', 'productIds'], []);
    const { link, text } = getButtonInfo({ buttonLink, buttonText, seeMoreLink, viewType });
    const child = getChildren(children, productIds, publication);

    if (loading || error || !Array.isArray(child)) {
      return <></>;
    }

    return (
      <WrapperdComponent
        buttonLink={link}
        buttonText={text}
        viewType={viewType}
        isPickupStore={isPickupStore}
        descriptionText={descriptionText}
        ignoreScrollBar={ignoreScrollBar}
        renderComponent={renderComponent}
        shelfTitle={shelfTitle}
        child={child}
      />
    );
  };

  return EnhancedVitrine;
};
