import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import Icon from '@catalogo/ui-americanas-desktop-svg/icon-plus.svg';

const Grid = ({ child, renderComponent, shelfTitle, buttonLink, buttonText }) => (
  <Container>
    <VitrineTitle>{shelfTitle}</VitrineTitle>
    <ProductContainer>
      {child.slice(0, 20).map((product, index) => {
        const orientation = index <= 1 ? 'row' : 'column';

        return (
          <CardContainer key={product}>
            <CardNumber>{`${index + 1}`}</CardNumber>
            <Card>{renderComponent({ ...product, orientation, viewType: 'grid' })}</Card>
          </CardContainer>
        );
      })}
      <SeeMore to={buttonLink}>
        <PlusIcon />
        <Text>{buttonText}</Text>
      </SeeMore>
    </ProductContainer>
  </Container>
);

export default Grid;

const Container = styled.div``;

const Card = styled.div``;

const VitrineTitle = styled.span`
  font-size: 26px;
  font-weight: 700;
  color: #666;
  text-transform: lowercase;
  margin: 0 0 24px;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CardContainer = styled.div`
  flex: 0 0 20%;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  border-right: solid 1px #e5e5e5;
  margin-bottom: 30px;

  &:nth-child(-n + 2) {
    justify-content: flex-start;
    flex: 1 1 calc(50% - 20px);
    position: relative;
    max-width: 50%;
  }
  &:nth-child(1) {
    margin-left: 0;
  }
  &:nth-child(5n + 2) {
    border-right: none;
  }
`;

const CardNumber = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  border: solid 1px #ccc;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SeeMore = styled(Nav)`
  background: #fafafa;
  border: solid 1px #e5e5e5;
  display: flex;
  flex-direction: column;
  flex: 0 0 20%;
  justify-content: center;
  align-items: center;
  height: 377px;
  cursor: pointer;
  border-left: 0;
`;

const PlusIcon = styled(Icon)`
  width: 22px;
  height: 22px;
`;

const Text = styled.span`
  margin-top: 6px;
  color: #666;
`;
