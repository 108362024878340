import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import Icon from '@catalogo/ui-americanas-desktop-svg/icon-plus.svg';

const Grid = ({ child, renderComponent, shelfTitle, buttonLink, buttonText }) => (
  <Container>
    <VitrineTitle>{shelfTitle}</VitrineTitle>
    <ProductContainer>
      {child.slice(0, 20).map((product, index) => (
        <CardContainer key={product}>
          <CardNumber>{`${index + 1}`}</CardNumber>
          <Card>{renderComponent({ ...product, orientation: 'row', viewType: 'grid' })}</Card>
        </CardContainer>
      ))}
      <SeeMore to={buttonLink}>
        <PlusIcon />
        <Text>{buttonText}</Text>
      </SeeMore>
    </ProductContainer>
  </Container>
);

export default Grid;

const Container = styled.div`
  padding: 0 10px;
`;

const Card = styled.div``;

const VitrineTitle = styled.span`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #666;
  text-transform: lowercase;
  margin: 0 0 10px;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  justify-content: flex-start;
  flex: 0 0 100%;
  position: relative;
`;

const CardNumber = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  border: solid 1px #ccc;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SeeMore = styled(Nav)`
  border-top: solid 1px #ccc;
  display: flex;
  flex-direction: row;
  flex: 0 0 calc(100% - 20px);
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  cursor: pointer;
`;

const PlusIcon = styled(Icon)`
  width: 22px;
  height: 22px;
`;

const Text = styled.span`
  color: #666;
`;
