import React from 'react';
import styled from 'styled-components';
import { withVitrine } from '@catalogo/service-americanas-vitrine/src/hoc/vitrine';
import Grid from './components/grid';
import VitrineCarousel from './components/carousel';

export const ZionVitrine = ({
  viewType,
  child,
  buttonLink,
  buttonText,
  isPickupStore,
  descriptionText,
  renderComponent,
  shelfTitle,
}) => (
  <Container>
    {viewType === 'carousel' && (
      <VitrineCarousel
        child={child}
        renderComponent={renderComponent}
        isPickupStore={isPickupStore}
        descriptionText={descriptionText}
        buttonText={buttonText}
        buttonLink={buttonLink}
      />
    )}
    {viewType === 'grid' && (
      <Grid
        child={child}
        renderComponent={renderComponent}
        buttonLink={buttonLink}
        buttonText={buttonText}
        shelfTitle={shelfTitle}
      />
    )}
  </Container>
);

export default withVitrine(ZionVitrine);

const Container = styled.div``;
